@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400&display=swap');

body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
}

h3 {
	font-style: italic;
}

#root {
	max-width: 80%;
	margin: 0 auto;
}

/* STICKY FOOTER */
#app {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}
main {
	flex: 1;
}
footer {
	text-align: center;
	margin: 3rem 0 2rem 0;
}
